<template>
    <loading v-if="submitting"></loading>
    <div class="scrolling-columns calendar-page" v-else>
        <div class="row">
            <div class="calendar-column" 
                 :class="{ 'col-12': !editSession && !editCategory && !showCategoryList, 'editor-visible col-6': editSession || editCategory || showCategoryList }">
                
                <div class="row mt-2">
                    <div class="col">
                        <h5>Session List</h5>
                    </div>                    
                    <div class="col-auto">
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-outline-primary" @click="showCategoryList = !showCategoryList">
                                Edit Categories
                            </button>
                            <router-link class="btn btn-sm btn-outline-primary" :to="{ name: 'ShowRundown' }">
                                Rundown
                            </router-link>
                            <button @click="copyWatchLinks" class="btn btn-sm btn-outline-primary"
                                    v-if="show.IsPublicShow">
                                Copy Watch Links
                            </button>   
                            <button @click="copyShortLinks" class="btn btn-sm btn-outline-primary"
                                    v-if="show.IsPublicShow">
                                Copy Short Links
                            </button>   
                            <button type="button" class="btn btn-sm btn-outline-primary"
                                    @click="requestFlashThumbnailUrl">
                                Set Poster...    
                            </button>
                        </div>                        
                    </div>                    
                </div>
                
                <template v-for="(sessionList, ix) in sessions">
                    <h6>
                        {{ getCategoryName(ix) }}
                    </h6>
                    <table class="table session-table table-sm table-striped table-hover">
                        <thead>
                            <tr>
                                <th v-if="show.IsPublicShow">
                                </th>
                                <th scope="col" style="width: 1%;">
                                    Code
                                </th>
                                <th scope="col" style="width: 100%">
                                    Title
                                </th>
                                <th scope="col" style="min-width: 250px;">
                                    Date/Time
                                </th>
                                <th v-if="!editSession" scope="col" style="min-width: 150px;">
                                    Duration
                                </th>
                            </tr>
                        </thead>
                        <tbody class="table-group-divider">
                            <tr v-for="session in sessionList"
                                @click="editSession = session"
                                class="pe-action"
                                :class="{'table-active': editSession && editSession.Code == session.Code }">
                                <td v-if="show.IsPublicShow">
                                    <div class="btn-group">
                                        <button @click.stop="copyWebinarWatchLink(session)" class="btn btn-sm btn-outline-primary">
                                            <i class="bi bi-share"></i>
                                        </button>
                                        <button @click.stop="createShortLink(session, true)" class="btn btn-sm btn-outline-primary">
                                            <i class="bi bi-link-45deg"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="w-auto font-monospace">
                                    {{ session.Code }}
                                </td>
                                <td>
                                    {{ session.Name }}
                                </td>
                                <td>
                                    {{ session.SessionStartDateTime | moment('YYYY-MM-DD HH:mm') }} - {{ session.SessionEndDateTime | moment('HH:mm') }}
                                </td>
                                <td v-if="!editSession">
                                    {{ getDuration(session) }} minutes
                                </td>
                            </tr>
                        </tbody>
                        <button @click="createSession(ix)" class="btn ms-1 mt-1 btn-outline-success btn-sm">Add...</button>
                    </table>
                </template>
            </div>
            <div class="col-6" v-if="editSession || editCategory || showCategoryList">
                <div class="category-list" v-if="showCategoryList">

                    <table class="table table-hover table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Code
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category in show.SessionCategories"
                                :key="category.Code"
                                class="pe-action"
                                :class="[editCategory && editCategory == category ? 'table-primary' : '']"
                                @click="onClickCategory(category)">
                                <td>
                                    {{ category.Name }}
                                </td>
                                <td>
                                    {{ category.Code }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="10">
                                    <button class="btn btn-primary btn-sm"
                                            @click="addCategory">
                                        Add...    
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>    
                </div>
                <session-editor :session="editSession"
                                :show="show"
                                v-if="editSession"
                                v-on:deleted="onDeleted"
                                v-on:cancel="onEditCancel"
                                v-on:saved="onEditSave">
                </session-editor>
                <session-category-editor :show="show"
                                    :sessionCategory="editCategory"
                                    :isNewCategory="editCategory.Id == '0'"
                                    @saved="onEditCategorySave"
                                    @cancel="onEditCategoryCancel"
                                    v-if="editCategory">
                </session-category-editor>               
            </div>

        </div>

    </div>
</template>
<style>
.vuecal {
    height: calc(100% - 52px) !important; 
}
/* .calendar-header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.calendar-header > button {
    margin-right: 0.5em;
}

.category-list > button {
    margin: 0.2em;
}

.calendar-editor {
    display: flex;
}

.calendar-column {
    height: calc(100vh - 330px);
    width: 100%;
}

.editor-visible {
    width: 40% !important;
}

.calendar-editor > div:first-child {
    height: 100%;
}
*/

.vuecal__event {
    color: white !important;
    background-color: rgba(0,129,255,0.8) !important;
}

.vuecal__cell-split {
    border-left: 1px solid lightblue;
}

</style>
<script>
import Vue from 'vue'
import moment from 'moment';
import Token from './authentication/token'
import Session from '../models/Session'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import AgendaItemCategory from '../models/AgendaItemCategory'
import Common from './common'
import Toastify from 'toastify-js'

let localBus = new Vue();

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],

    watch: {
        showDetails(to, from) {
            this.show = to;
        }
    },

    computed: {
        webinarPageUrl() {
            return process.env.VUE_APP_WEBINAR_APP_URL;
        }
    },    

    methods: {
        async createShortLink(session, showSuccess) {
            let targetUrl = `${this.webinarPageUrl}/watch/${this.show.Code}/${session.Code}`;

            let shortLink = {
                ShowCode: this.show.Code.toLowerCase(),
                UrlCode: session.Code.toLowerCase(),
                Description: `Player Page - ${session.Name}`,
                TargetUrl: targetUrl
            }

            let resultRaw = await this.tryPost('/api/shorturl', JSON.stringify([shortLink]), 'application/json');
            let shortLinkDestination = `https://u.tractus.ca/${this.show.Code.toLowerCase()}/${session.Code.toLowerCase()}`;

            if(showSuccess) {
                Toastify({
                    text: "Saved short URL & copied to clipboard.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();

                navigator.clipboard.writeText(shortLinkDestination)
            }
        },

        async copyShortLinks(e) {
            let includeTitle = e && e.shiftKey;
            let links = '';
            Object.keys(this.sessions).forEach((x) => {
                let sessionList = this.sessions[x];

                for(let i = 0; i < sessionList.length; i++) {
                    let session = sessionList[i];

                    if(includeTitle) {
                        links = links + session.Name + "\n";
                    }

                    links = links + `https://u.tractus.ca/${this.show.Code.toLowerCase()}/${session.Code.toLowerCase()}` + "\n";

                    if(includeTitle) {
                        links = links + '\n';
                    }
                    this.createShortLink(session, false);  
                }
            });

            navigator.clipboard.writeText(links);

            Toastify({
                text: "Copied short links to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();

        },

        copyWatchLinks() {
            let links = '';
            console.log('Session list:', this.sessions);

            Object.keys(this.sessions).forEach((x) => {
                let sessionList = this.sessions[x];

                for(let i = 0; i < sessionList.length; i++) {
                    let session = sessionList[i];
                    links = links + `${this.webinarPageUrl}/watch/${this.show.Code}/${session.Code}` + "\n";
                }
            });


            navigator.clipboard.writeText(links);

            Toastify({
                text: "Copied watch links to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        copyWebinarWatchLink(session) {
            let url = `${this.webinarPageUrl}/watch/${this.show.Code}/${session.Code}`;
            navigator.clipboard.writeText(url);
            Toastify({
                text: "Copied webinar watch link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();

        },

        getDuration(session) {
            let duration = moment.duration(moment(session.SessionEndDateTime).diff(moment(session.SessionStartDateTime)));
            
            return duration.asMinutes();
        },

        getCategoryName(categoryCode) {
            if(!categoryCode) {
                return `${categoryCode} (Not Found)`;
            }

            try {
                let category = this.show.SessionCategories.find(x => x.Code == categoryCode);

                return category.Name;
            } catch {
                return `${categoryCode} (Not Found)`;
            }
        },

        async requestFlashThumbnailUrl() {
            let result = prompt("Provide an image URL to use for the cover photo for ALL sessions.");

            if(!result) {
                return;
            }

            let toUpdate = [];
            for(const [k, sessions] of Object.entries(this.sessions))
            {
                toUpdate.push(...sessions);
            }

            for(let i in toUpdate) {
                let session = toUpdate[i];

                if(session.PrimaryMedia) {
                    session.PrimaryMedia = {
                        Code: this.createUniqueCode(),
                        Uri: "",
                        MediaType: "Video",
                        AutoPlay: false,
                        ThumbnailUri: "",
                        Title: "",
                        Description: "",
                        PlayAfterExit: false
                    }
                }  

                session.PrimaryMedia.ThumbnailUri = result;
            }
            this.submitting = true;

            try {
                let saveResult = await this.tryPost('/api/session/batch', JSON.stringify(toUpdate), 'application/json');
                if(saveResult.Errors.length == 0) {
                    this.showToast("Updated all cover photos.");
                } else {
                    throw saveResult.Errors[0];
                }
            } catch(ex) {
                console.error(ex);
                this.showToast("Error occurred.");
            }

            this.submitting = false;
            this.reloadSessions();
        },

        async onEventDrop(e, ee) {
            if(e.newSplit) {
                e.event.session.Category.Code = e.newSplit;
            }

            e.event.session.SessionStartDateTime = e.event.start;
            e.event.session.SessionEndDateTime = e.event.end;

            try {
                await this.tryPost('/api/session', JSON.stringify(e.event.session), 'application/json');
            } catch(ex) {
                alert("We could not save your changes. The page will now reload.");
            }

            this.reloadSessions();
        },

        addCategory() {
            this.editCategory = null;
            
            Vue.nextTick(() => {
                let newCategory = new AgendaItemCategory();
                newCategory.Id = '0';
                newCategory.Code = this.createUniqueCode();

                this.editCategory = newCategory;
            });
        },

        scrollToCurrentTime() {
            const calendar = document.querySelector("#calendar .vuecal__bg");
            const hours = new Date().getHours() + new Date().getMinutes() / 60
            calendar.scrollTo({ top: 7 * 100 })
        },

        onViewChange(e) {
            this.splitDays = [];
            if(e.view == 'day') {

                for(let i = 0; i < this.show.SessionCategories.length; i++) {
                    this.splitDays.push({
                        id: this.show.SessionCategories[i].Code,
                        label: this.show.SessionCategories[i].Name,
                        category: this.show.SessionCategories[i]
                    });
                }
            }
        },

        onClickEvent(e) {
            this.editSession = null;
            Vue.nextTick(() => {
                this.editSession = e;
            });
        },

        createSession(categoryCode) {
            let newSession = new Session()
            newSession.SessionStartDateTime = new Date();
            newSession.SessionEndDateTime = new Date();
            newSession.Category = {
                Code: categoryCode
            };
            newSession.Show = {
                Code: this.show.Code
            };
            newSession.ContactInfo = new SessionContactInfo();
            this.editSession = newSession;
        },

        onClickCategory(e) {
            this.editCategory = null;
            Vue.nextTick(() => {
                this.editCategory = e;
            });
        },

        onEditCategoryCancel() {
            this.editCategory = null;
        },

        onEditCategorySave() {
            this.editCategory = null;
            this.showCategoryList = false;
            this.bus.$emit('Do-Show-Reload');            
        },

        onEditCancel() {
            this.editSession = null;
            this.reloadSessions();
        },

        onDeleted(sessionFromService) {
            this.editSession = null;
            this.reloadSessions();
        },

        onEditSave(show, sessionFromService) {
            this.editSession = null;
            this.reloadSessions();    
        },

        async reloadSessions() {
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/sessions`);
                this.sessions = result.Result;
                console.log(this.sessions, 'SESSIONS', this.showDetails)

                for(let i = 0; i < this.showDetails.SessionCategories.length; i++) {
                    let category = this.showDetails.SessionCategories[i];
                    console.warn(category)
                    if(!this.sessions[category.Code]) {
                        console.warn("Not exist")
                        this.sessions[category.Code] = [];
                    }
                }
                console.log(this.sessions, 'SESSIONS')
            } catch {
                alert("Could not load the calendar. Please try again later.");
            }

            // this.sessionCalendar = [];

            // for(const [k, sessions] of Object.entries(this.sessions))
            // {
            //     this.sessionCalendar.push(...sessions.map(s => {
            //         let toReturn = {
            //             session: s,
            //             start: new Date(s.SessionStartDateTime),
            //             end: new Date(s.SessionEndDateTime),
            //             title: s.Name,
            //             class: `S-${k}`,
            //             split: s.Category.Code
            //         };
            //         this.allSessions.push(...sessions);
            //         return toReturn;
            //     }))
            // }
            
            // //this.editSession = this.sessionCalendar[0];
        },

        async build() {
            this.submitting = true;

            await this.reloadSessions();

            this.submitting = false;
        }
    },

    data() {
        return {
            sessionCalendar: [],
            allSessions: [],
            showCategoryList: false,

            editSession: null,
            editCategory: null,
            
            show: null,
            
            selectedDate: null,

            splitDays: [],
            sessions: [],
        }
    },

    created: function() {
        this.show = this.showDetails;
        this.selectedDate = new Date(this.show.StartDateTime);

        localBus.$on("editCategory", (c) => {
            if(this.displayMode != 'Categories') {
                return;
            }

            if(this.editCategory != null) {
                this.editCategory = null;
            }

            this.$nextTick(() => {
                this.editCategory = this.show.SessionCategories.find(x=>x.Code == c);
            });          

        });

        localBus.$on('newCategory', () => {
            let newCategory = new AgendaItemCategory();
            newCategory.Id = '0';
            newCategory.Code = this.createUniqueCode();

            this.editCategory = newCategory;
        });

        this.onViewChange({
            view: 'day'
        });
        this.build();     

    },
}
</script>