<template>
    <div v-if="editShow" class="container-fluid">

        <show-basic-details-wizard-page :show="editShow" 
                                        :generateCode="false"
                                        :submitting="submitting"></show-basic-details-wizard-page>

        <div>


            <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                Oops! It looks like something happened. Please double-check and try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <button type="button" class="btn btn-primary" :class="{'ms-auto': !submitting}" 
                @click="onSave" 
                :disabled="submitting">
                <i class="bi bi-save pe-1"></i>
                Save Changes
            </button>

            <div v-if="submitting" class="ms-auto pe-1">
                <loading>
                </loading>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

var labelLookup = {
    Agenda: "Agenda",
    Companies: "Companies & Sponsors",
    Polls: "Open Polls/Q&A",
    Announcements: "Announcements"
};

export default {
    props: ['showDetails','shows','notStandalone'],
    mixins: [
        Token,
        Common
    ],


    data() {
        return {
            editShow: null,
            uploadBus: new Vue(),
            validCode: true,
            validDate:true,
            upload: {
                Url: this.getApiUrl() + '/api/assets/show?showCode' + this.showDetails.Code,
                Headers: {
                    'Authorization': 'Bearer ' + this.getUserDetails().token
                }
            }
        }
    },

    created: function() {
        var show = this.cloneObject(this.showDetails);

        if(!show.Theme) {
            show.Theme = {
                HomeBackgrounds: [],
                StyleSheet: ""
            };
        }

        if(!show.ShowHeaderImagePath) {
            show.ShowHeaderImagePath = '';
        }


        this.editShow = show;
        this.uploadBus.$on('uploadSuccess', r => {
            this.editShow.ShowHeaderImagePath = r.Result;
        });
    },

    methods: {
        validateForm: function() {

            this.validateErrors = [];
            this.validCode = true;
            this.validDate = true;

            var str = this.editShow.Name;
            if (this.editShow.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Show Name cannot contain only spaces.");
            }
            if(!this.editShow.Name) {         
            this.validateErrors.push("Show Name cannot be blank.");
            }
            if(!this.editShow.StartDateTime && this.editShow.EndDateTime) {
                this.validateErrors.push("Show Start Date/Time cannot be blank.");
            }
            if(!this.editShow.EndDateTime && this.editShow.StartDateTime) {
                this.validateErrors.push("Show End Date/Time cannot be blank.");
            }
             if(!this.editShow.StartDateTime && !this.editShow.EndDateTime) {
                this.validateErrors.push("Show Start Date/Time and End Date/Time cannot be blank.");
            }
            if(!this.validDate){
                this.validateErrors.push("Show End Date/Time must be after the Start Date/Time.");
            }
            // todo; what is allowed in a show code
            // var letters = /^[0-9a-zA-Z]+$/;
            // if(this.editShow.Code && !this.editShow.Code.match(letters)){
            //     this.validateErrors.push("Show Code can only have numbers and letters.");
            // }
            if(!this.editShow.Code) {
                this.validateErrors.push("Show Code cannot be blank.");
            }
            return this.validateErrors.length === 0;
        },
        async onSave() {
            
            this.submitting = true;

            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }
            this.editShow.Code = this.editShow.Code.toUpperCase();

            var toSave = JSON.parse(JSON.stringify(this.editShow));
            
            try{
                let r = await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
                
                if(r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                }
                else{
                    this.$router.push('/shows/'+this.editShow.Code);
                    this.bus.$emit('Do-Show-Reload');
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;

        },
    },
}
</script>
